<template>
  <div>
    <v-skeleton-loader v-if="loading.devices"
                       class="mb-5"
                       type="table"/>

    <content-card v-else
                  color="primary"
                  class="mb-5"
                  :title="$t('accesses-card.title')"
                  icon="key"
                  v-show="this.devices.length > 0">

      <template v-slot:content>
        <v-data-table :headers="headers"
                      :items="devices"
                      item-key="id"
                      class="row-pointer tileBackground"
                      mobile-breakpoint="0"
                      :items-per-page="-1"
                      hide-default-header
                      hide-default-footer
                      @click:row="openLockDevice">

          <template v-slot:[`item.name`]="{ item }">
            <v-list-item class="pa-1">
              <v-list-item-icon class="mr-2">
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="device-title">
                <v-list-item-title class="primary--text font-weight-bold font-size-03">
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="font-size-02" v-text="item.type"/>
              </v-list-item-content>
            </v-list-item>

          </template>

          <template v-slot:[`item.batteryLevel`]="{ item }">
            <v-icon v-if="item.batteryLevel === 0" color="error">battery_alert</v-icon>
            <v-icon v-else-if="item.batteryLevel === 1" color="warning">battery_2_bar</v-icon>
            <v-icon v-else-if="item.batteryLevel === 5" color="primary">battery_full</v-icon>
            <v-icon v-else-if="item.batteryLevel > 0" color="primary">{{
                "battery_" + (item.batteryLevel + 2) + "_bar"
              }}
            </v-icon>
          </template>
        </v-data-table>
      </template>
    </content-card>

  </div>

</template>

<script>
import ContentCard from "@/templates/components/ContentCard.vue";

export default {
  name: "LockDeviceList",
  components: {ContentCard},
  data() {
    return {
      loading: {
        devices: false
      },
      timeout: false,
      headers: [
        {
          align: 'start',
          value: 'name',
        },
        {
          align: 'center',
          value: 'batteryLevel',
          cellClass: 'width-20'
        },
      ],
      devices: []
    }
  },
  methods: {
    getWilkaDevices() {
      this.loading.devices = true

      this.$rhRequest.sendGet({
            endpoint: 'wilka?filter[siteIds]=' + localStorage.activeSite + '&fields[lockCylinders]=name,batteryLevel,bluetoothId',
          },
          (r) => {
            let batteryLevels = this.mapBatteryLevels(r?.data?.included)
            this.devices = []
            r?.data?.data.forEach((e) => {
              this.devices.push({
                icon: 'lock',
                id: e.id,
                name: e.attributes.name,
                bluetoothId: e.attributes.bluetoothId,
                batteryLevel: batteryLevels.get(e.relationships.batteryLevel.data.id),
                type: this.$t('wilka.lockCylinder.type.label')
              })
            })

            this.loading.devices = false
          },
          (e) => {
            this.loading.devices = false
            console.error(e)
          }
      )
    },

    mapBatteryLevels(list) {
      let batteryLevels = new Map();
      list.forEach((e) => {
        batteryLevels.set(e.id, e.attributes.value)
      })
      return batteryLevels;
    },

    openLockDevice(row) {
      this.$root.bisadialog.toggle('wilkaLock', true, {
        params: {
          name: row.name,
          bluetoothId: row.bluetoothId,
          id: row.id
        }
      })
      this.$root.bisadialog.callDialogInit('wilkaLock')

      // listen for close on the Wilka lock dialog close to reload the lock list for updating with possible changes
      this.$root.$on('wilka-lock-dialog-close', () => {
        this.getWilkaDevices()
        this.$root.$off('wilka-lock-dialog-close')
      })
    }
  },

  mounted() {
    this.getWilkaDevices()
  }
}
</script>

<style lang="scss">
.width-20 {
  width: 20px;
}
</style>
