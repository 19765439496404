<template>
<fullscreen-overlay-frame :title="values.name"
                            :icon="icon"
                            color="primary"
                            centered
                            closable
                            :hide-actions-slot="!isSiteAdmin"
                            @close="close">
  <template v-slot:content>
    <v-row>
      <v-col cols="12" :md="fullscreen ? '6' : '12'">
        <content-card :title="$t('device-dialog.infos.title').toString()" icon="info">
          <template v-slot:content>
            <v-list class="pa-0">
              <!-- device name -->
              <v-list-item class="list-item">
                <v-list-item-icon>
                  <v-icon class="material-icons-outlined">label</v-icon>
                </v-list-item-icon>
                <v-list-item-content v-if="edit.name">
                  <v-text-field outlined dense hide-details
                                ref="name-input"
                                v-model="values.name"/>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title class="font-size-03"
                                     v-text="values.name"/>
                  <v-list-item-subtitle class="font-size-02"
                                        v-html="$t('add-wilka-lock-dialog.field.name')"/>
                </v-list-item-content>
                <v-list-item-action v-if="edit.name">
                  <v-btn icon
                         :loading="loading.name"
                         @click="updateName"
                         :rules="rules.name">
                    <v-icon>check</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action v-else-if="isSiteAdmin">
                  <v-btn icon
                         @click="changeName">
                    <v-icon class="non-flip">edit</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <!-- device type -->
              <v-list-item class="list-item">
                <v-list-item-icon>
                  <v-icon class="material-icons-outlined">category</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-size-03"
                                     v-text="$t('wilka.lockCylinder.type.label')"/>
                  <v-list-item-subtitle class="font-size-02"
                                        v-html="$t('device-dialog.infos.type')"/>
                </v-list-item-content>
              </v-list-item>

              <!-- bluetoothId -->
              <v-list-item class="list-item">
                <v-list-item-icon>
                  <v-icon class="material-icons-outlined">bluetooth</v-icon>
                </v-list-item-icon>
                <v-list-item-content v-if="edit.bluetoothId">
                  <v-form ref="form_ref_bluetoothId">
                    <v-text-field outlined dense hide-details
                                  ref="bluetoothId-input"
                                  v-model="values.bluetoothId"
                                  :rules="rules.bluetoothId"/>
                  </v-form>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title class="font-size-03"
                                     v-text="values.bluetoothId"/>
                  <v-list-item-subtitle class="font-size-02"
                                        v-html="$t('add-wilka-lock-dialog.field.bluetoothAddress')"/>
                </v-list-item-content>
                <v-list-item-action v-if="edit.bluetoothId">
                  <v-btn icon
                         :loading="loading.bluetoothId"
                         @click="updateBluetoothId">
                    <v-icon>check</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action v-else-if="isSiteAdmin">
                  <v-btn icon
                         @click="changeBluetoothId">
                    <v-icon class="non-flip">edit</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <!-- communication key -->
              <v-list-item class="list-item">
                <v-list-item-icon>
                  <v-icon class="material-icons-outlined">password</v-icon>
                </v-list-item-icon>
                <v-list-item-content v-if="edit.comKey">
                  <v-text-field outlined dense hide-details
                                ref="comkey-input"
                                v-model="comKey"
                                :rules="rules.comKey"/>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title class="font-size-03">*****</v-list-item-title>
                  <v-list-item-subtitle class="font-size-02"
                                        v-html="$t('add-wilka-lock-dialog.field.communicationKey')"/>
                </v-list-item-content>
                <v-list-item-action v-if="edit.comKey">
                  <v-btn icon
                         :loading="loading.changeComKey"
                         @click="updateComKey">
                    <v-icon>check</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action v-else-if="isSiteAdmin">
                  <v-btn icon
                         @click="changeComKey">
                    <v-icon class="non-flip">edit</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>
        </content-card>
      </v-col>
    </v-row>
  </template>
  <template v-slot:actions>
    <v-btn outlined
           v-if="isSiteAdmin"
           class="font-weight-bold action-button"
           color="primary"
           @click="removeLock">
      <v-icon left
              class="material-icons-outlined">
        delete
      </v-icon>
      {{ $t('device-dialog.remove-device') }}
    </v-btn>
  </template>
</fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame.vue";
import ContentCard from "@/templates/components/ContentCard.vue";
import CryptoJS from "crypto-js";
export default {
  name: "WilkaLockDialog",
  components: {ContentCard, FullscreenOverlayFrame},
  props: ['data'],
  data() {
    return {
      fullscreen: false,
      isSiteAdmin: null,
      icon: 'lock',
      values: {
        id: null,
        name: null,
        bluetoothId: null,
        comKey: null,
      },
      oldValues: {
        name: null,
        bluetoothId: null,
      },
      edit: {
        name: false,
        bluetoothId: false,
        comKey: false,
      },
      loading: {
        name: false,
        bluetoothId: false,
        comKey: false
      },
      rules: {
        name: [
          v => !!v || this.$t("add-wilka-lock-dialog.validation.notEmpty"),
          v => (v && v.length <= 30) || this.$t("add-wilka-lock-dialog.validation.name.length", {maxLength: '30'})
        ],
        bluetoothId: [
          v => !!v || this.$t("add-wilka-lock-dialog.validation.notEmpty"),
          v => (v && ((/^([0-9A-F]{2}:){5}([0-9A-F]{2})$/.test(v)) || (/^([0-9A-F]{12})$/.test(v)))) || this.$t('add-wilka-lock-dialog.validation.bluetoothId.length'),
        ],
        comKey: [
          v => !!v || this.$t("add-wilka-lock-dialog.validation.notEmpty"),
          v => v.toString().length >= 6 || this.$t('add-wilka-lock-dialog.validation.communicationKey.length'),
          v => v.toString().length <= 16 || this.$t('add-wilka-lock-dialog.validation.communicationKey.length')
        ],
      }
    }
  },
  methods: {
    init() {
      this.isSiteAdmin = this.$rhAuth.isSiteAdmin()
      this.values.name = this.data.params.name
      this.values.bluetoothId = this.data.params.bluetoothId
      this.values.id = this.data.params.id
    },

    formatBluetoothId(bluetoothId) {
      if(!bluetoothId.includes(':')) {
        return bluetoothId.replace(/(..)/g, '$1:').slice(0,-1).toUpperCase()
      }
      else {
        return bluetoothId.toUpperCase()
      }
    },

    checkBluetoothIdUniqueness() {
      return new Promise((resolve, reject) => {

        let btid = this.formatBluetoothId(this.values.bluetoothId)
        this.$rhRequest.sendGet(
            {
              endpoint: 'wilka?filter[bluetoothId]=' + btid,
            },
            (response) => {
              let result = response.data.data.length < 1
              if (!result) {
                this.$refs['bluetoothId-input'].errorMessages = [this.$t('add-wilka-lock-dialog.exists.error')]
                reject()
              } else {
                this.$refs['bluetoothId-input'].errorMessages = ''
                resolve()
              }
            },
            (e) => {
              console.error(e)
              reject()
            }
        )
      })
    },

    changeName() {
      this.edit.name = true
      this.oldValues.name = this.values.name
      this.$nextTick(() => { // wait until refs are defined
        this.$refs['name-input'].focus()
      })
    },
    updateName() {
      if (this.oldValues.name === this.values.name) {
        this.edit.name = false
        return
      }

      if (this.$refs['name-input'].validate()) {
        let attributes = {name: this.values.name}
        this.updateLock(attributes, 'name')
      }
    },

    changeBluetoothId() {
      this.edit.bluetoothId = true
      this.oldValues.bluetoothId = this.values.bluetoothId
      this.$nextTick(() => { // wait until refs are defined
        this.$refs['bluetoothId-input'].focus()
      })
    },
    updateBluetoothId() {
      if (this.oldValues.bluetoothId === this.values.bluetoothId) {
        this.edit.bluetoothId = false
        return
      }

      this.values.bluetoothId = this.formatBluetoothId(this.values.bluetoothId)
      this.checkBluetoothIdUniqueness().then(
          () => {
            if (this.$refs['bluetoothId-input'].validate()) {
              let attributes = {bluetoothId: this.values.bluetoothId}
              this.updateLock(attributes, 'bluetoothId')
            }
          }
      )
    },

    changeComKey() {
      this.edit.comKey = true
      this.$nextTick(() => { // wait until refs are defined
        this.$refs['comkey-input'].focus()
      })
    },
    updateComKey() {
      if (this.$refs['comkey-input'].validate()) {
        let communicationKeyMD5 =  CryptoJS.MD5(this.values.name).toString()
        let attributes = {communicationKey: communicationKeyMD5}
        this.updateLock(attributes, 'comKey')
      }
    },

    removeLock() {
      let endpoint = `wilka/delete/${this.values.id}`

      this.$rhRequest.sendDelete({
        endpoint: endpoint
      },
          () => {
            this.$root.bisatoast.success({
              message: this.$t('wilka-lock-dialog.delete.success'),
              showCloseBtn: true
            })
            this.close()
          },

          () => {
            this.$root.bisatoast.error({
              message: this.$t('app.generic-error'),
              showCloseBtn: true
            })
            this.close()
          },
      )
    },

    updateLock(attributes, key) {
      this.loading[key] = true

      let payload = {
        "data": {
          "type": "lockCylinders",
          "attributes": attributes
        }
      }

      let endpoint = `wilka/patch/${this.values.id}`

      this.$rhRequest.sendPatch({
        endpoint: endpoint,
        data: payload
      },
          () => {
              this.loading[key] = false
              this.edit[key] = false
              this.$root.bisatoast.success({
                message: this.$t('wilka-lock-dialog.update.success'),
                showCloseBtn: true
            })
          },
          () => {
              this.loading[key] = false
              this.$root.bisatoast.error({
                message: this.$t('app.generic-error'),
                showCloseBtn: true
            })
          }
      )
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('wilkaLock')
      this.$root.$emit('wilka-lock-dialog-close')
    }
  },
}
</script>

